import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpInterceptorService } from './shared/interceptors/http-interceptor.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler/global-error-handler.service';
import { LocalStorageKey } from './shared/constants/local-storage-key.const';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { usermanagementReducer } from './features/user-management/ngrx/user-management.reducer';
import { provideEffects } from '@ngrx/effects';
import { UserManagementEffects } from './features/user-management/ngrx/user-management.effects';
import { appComponentReducer } from './state/app-component.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      OktaAuthModule.forRoot({
        oktaAuth: new OktaAuth({
          issuer: environment.OKTA_CONFIGS.ADI_HOME.ISSUER_URL,
          clientId: environment.OKTA_CONFIGS.ADI_HOME.CLIENT_ID,
          redirectUri: `${window.location.origin}/login/callback`,
          postLogoutRedirectUri: `${window.location.origin}/${localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE)}/login`,
          scopes: ['openid', 'offline_access', 'profile', 'email'],
          acrValues: 'urn:okta:loa:2fa:any',
          tokenManager: {
            storage: 'cookie',
            storageKey: environment.OKTA_CONFIGS.ADI_HOME.CLIENT_ID,
          },
          cookies: {
            secure: true,
          },
        }),
      })
    ),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({
      usermanagement: usermanagementReducer,
      appComponent: appComponentReducer,
    }),
    provideEffects([UserManagementEffects]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ],
};
