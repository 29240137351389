export function constructEntitlementPayload(
  permissionJson: any,
  userDetailsData: any,
  associatedFacilitiesData: any,
  activeTab: string
) {
  const permissionObjAPI = userDetailsData?.associatedAccounts?.find(
    (value: any) => value?.type === activeTab
  )?.permissions;

  // Update the API using the function
  const updatedApi = updateApiValues(
    permissionJson[activeTab],
    permissionObjAPI,
    associatedFacilitiesData,
    activeTab
  );

  return {
    permissions: updatedApi,
    accessType: userDetailsData?.associatedAccounts?.find(
      (value: any) => value?.type === activeTab
    )?.accessType,
  };
}

// Function to update the API based on the JSON structure
function updateApiValues(
  json: any,
  api: any,
  associatedFacilitiesData: any,
  activeTab: string
) {
  const jsonClone = deepClone(json); // Deep clone the JSON to avoid mutation
  const apiClone = deepClone(api); // Deep clone the API to avoid mutation

  // First set the toggle
  Object.keys(apiClone)?.forEach((key) => {
    if (jsonClone.hasOwnProperty(key)) {
      apiClone[key].active = jsonClone[key].value;

      // Second set checkbox ( and radio button if present)
      switch (key.toLowerCase()) {
        case 'cc':
          const surgeryMgmtValue = jsonClone[
            key
          ]?.permissionSet?.surgeryMgmt?.options?.find(
            (val: any) => val?.value === true
          )?.code;

          if (apiClone[key]?.permissionSet && surgeryMgmtValue) {
            apiClone[key].permissionSet['surgeriesDash'].surgeryMgmt =
              surgeryMgmtValue;
          }

          break;

        case 'sched':
          // Case - Clinic Auto Scheduler (has associated facilies)
          if (
            key === 'SCHED' &&
            activeTab?.toLowerCase() === 'clinic' &&
            associatedFacilitiesData?.associatedFacilities?.length
          ) {
            // Associated Facilities
            const jsonPerm =
              jsonClone[key]?.permissionSet?.associatedFacilities?.tableBody;
            const apiPerm =
              apiClone[key]?.permissionSet?.associatedFacilitiesPermission;

            // Combination of facilities having view/manage and no-access before
            if (apiPerm?.length) {
              jsonPerm.forEach((item: any) => {
                const apiObj = apiPerm.find(
                  (apiItem: any) => apiItem.ascId === item.practice.ascId
                );

                // This facility had view/manage access before
                if (apiObj) {
                  if (item.scheduleMgmt.value.key === 'noAccess') {
                    // If scheduleMgmt is "noAccess", remove the object from the apiPerm array
                    const index = apiPerm.indexOf(apiObj);
                    if (index > -1) {
                      apiPerm.splice(index, 1);
                    }
                  } else {
                    apiObj.scheduleMgmt = item.scheduleMgmt.value.key;
                  }
                }
                // This facility had no access before
                else {
                  apiPerm?.push({
                    ascId: item.practice.ascId,
                    scheduleMgmt: item.scheduleMgmt.value.key,
                  });
                }
              });
            }
            // All the associated facilities have no-access before
            else {
              jsonPerm.forEach((item: any) => {
                if (item.scheduleMgmt.value.key !== 'noAccess') {
                  apiPerm?.push({
                    ascId: item.practice.ascId,
                    scheduleMgmt: item.scheduleMgmt.value.key,
                  });
                }
              });
            }

            // Rest permission set in auto scheduler
            setCheckboxAndRadioButtons(apiClone, jsonClone, key);
          }
          // Case - Asc Auto Scheduler
          else if (key === 'SCHED' && activeTab?.toLowerCase() === 'asc') {
            setCheckboxAndRadioButtons(apiClone, jsonClone, key);
          }
          break;

        default:
          // Case - Rest all permissions
          setCheckboxAndRadioButtons(apiClone, jsonClone, key);
          break;
      }
    }
  });

  return apiClone;
}

// Set checkbox and radio buttons
function setCheckboxAndRadioButtons(
  apiClone: any,
  jsonClone: any,
  key: string
) {
  if (apiClone[key]?.permissionSet) {
    Object.keys(jsonClone[key]?.permissionSet)?.forEach((permKey: any) => {
      if (
        jsonClone[key]?.permissionSet[permKey]?.show &&
        permKey !== 'associatedFacilities'
      ) {
        // If checkbox and radio button present
        if (jsonClone[key]?.permissionSet[permKey]?.options?.length) {
          const checkboxPermission =
            jsonClone[key]?.permissionSet[permKey]?.value;
          const radioPermission = jsonClone[key]?.permissionSet[
            permKey
          ]?.options?.find((val: any) => val?.value === true)?.code;

          // If checkbox enabled
          if (checkboxPermission) {
            apiClone[key].permissionSet[permKey] = radioPermission;
          }
          // If checkbox disabled
          else {
          }
        }
        // If only checkbox present
        else {
          apiClone[key].permissionSet[permKey] =
            jsonClone[key]?.permissionSet[permKey]?.value;
        }
      }
    });
  }
}

// Deep clone function to prevent mutations of original objects
function deepClone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}
