{
  "permissions": {
    "CLINIC": {
      "CC": {
        "modulename": "Adi Clinic Connect",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryMgmt": {
            "label": "Patient & Surgery Management",
            "type": "checkbox",
            "show": true,
            "value": true,
            "options": [
              {
                "code": "view",
                "type": "radio",
                "label": "View only",
                "show": true,
                "value": true
              },
              {
                "code": "manage",
                "type": "radio",
                "label": "Manage (view, add, edit)",
                "show": true,
                "value": false
              }
            ]
          }
        }
      },
      "SCHED": {
        "modulename": "Adi Auto Scheduler",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryScheduleSummary": {
            "label": "Surgery schedule summary",
            "type": "checkbox",
            "value": true,
            "show": true
          },
          "associatedFacilities": {
            "show": true,
            "type": "table",
            "tableHead": {
              "col1": {
                "key": "practice",
                "label": "Practices"
              },
              "col2": {
                "key": "scheduleMgmt",
                "label": "Schedule Details"
              },
              "col3": {
                "key": "maxPermissionLevel",
                "label": "Max. permission level"
              }
            },
            "tableBody": [
              {
                "practice": {
                  "type": "table-cell-text",
                  "value": "",
                  "ascId": ""
                },
                "scheduleMgmt": {
                  "type": "table-cell-dropdown",
                  "value": "",
                  "options": [
                    {
                      "key": "noAccess",
                      "label": "No Access"
                    },
                    {
                      "key": "view",
                      "label": "View Only"
                    },
                    {
                      "key": "manage",
                      "label": "Manage"
                    }
                  ]
                },
                "maxPermissionLevel": {
                  "type": "table-cell-text",
                  "value": "",
                  "options": [
                    {
                      "key": "noAccess",
                      "label": "No Access"
                    },
                    {
                      "key": "view",
                      "label": "View Only"
                    },
                    {
                      "key": "manage",
                      "label": "Manage"
                    }
                  ]
                }
              }
            ]
          }
        }
      },
      "SDO": {
        "modulename": "Adi Surgery Day Optimizer",
        "value": false,
        "show": false,
        "type": "toggle",
        "permissionSet": {
          "surgeryOptimizer": {
            "label": "Surgery Optimizer",
            "type": "checkbox",
            "show": true,
            "value": true
          }
        }
      },
      "IC": {
        "modulename": "Adi Insights Center",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryInsights": {
            "label": "Surgery insights",
            "type": "checkbox",
            "show": true,
            "value": true
          },
          "orderingInsights": {
            "label": "Ordering insights",
            "type": "checkbox",
            "show": false,
            "value": false
          },
          "revenueInsights": {
            "label": "Revenue insights",
            "type": "checkbox",
            "show": false,
            "value": false
          },
          "askMeAnything": {
            "label": "Ask me anything",
            "type": "checkbox",
            "show": false,
            "value": false
          }
        }
      }
    },
    "ASC": {
      "CC": {
        "modulename": "Adi Clinic Connect",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryMgmt": {
            "label": "Patient & Surgery Management",
            "type": "checkbox",
            "show": true,
            "value": true,
            "options": [
              {
                "code": "view",
                "type": "radio",
                "label": "View only",
                "value": true,
                "show": true
              },
              {
                "code": "manage",
                "type": "radio",
                "label": "Manage (view, add, edit)",
                "value": false,
                "show": true
              }
            ]
          }
        }
      },
      "SCHED": {
        "modulename": "Adi Auto Scheduler",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryScheduleSummary": {
            "label": "Surgery schedule summary",
            "type": "checkbox",
            "value": true,
            "show": true
          },
          "scheduleMgmt": {
            "label": "Schedule details",
            "show": true,
            "value": false,
            "type": "checkbox",
            "options": [
              {
                "type": "radio",
                "code": "view",
                "label": "View only",
                "value": true,
                "show": true
              },
              {
                "type": "radio",
                "code": "manage",
                "label": "Manage (view and edit)",
                "value": false,
                "show": true
              }
            ]
          }
        }
      },
      "SDO": {
        "modulename": "Adi Surgery Day Optimizer",
        "value": false,
        "show": false,
        "type": "toggle",
        "permissionSet": {
          "surgeryOptimizer": {
            "label": "Surgery Optimizer",
            "type": "checkbox",
            "show": true,
            "value": true
          }
        }
      },
      "IC": {
        "modulename": "Adi Insights Center",
        "value": true,
        "show": true,
        "type": "toggle",
        "permissionSet": {
          "surgeryInsights": {
            "label": "Surgery insights",
            "type": "checkbox",
            "show": true,
            "value": false
          },
          "orderingInsights": {
            "label": "Ordering insights",
            "type": "checkbox",
            "show": true,
            "value": false
          },
          "revenueInsights": {
            "label": "Revenue insights",
            "type": "checkbox",
            "show": false,
            "value": false
          },
          "askMeAnything": {
            "label": "Ask me anything",
            "type": "checkbox",
            "show": false,
            "value": false
          }
        }
      }
    }
  }
}
