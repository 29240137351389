import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppComponentState } from './app-component.reducer';

// Define the feature selector for App component
export const selectAppComponentState =
  createFeatureSelector<AppComponentState>('appComponent');

export const selectToastMessage = createSelector(
  selectAppComponentState,
  (state: AppComponentState) => state.showToastAlert
);

export const selectToastMessageDetails = createSelector(
  selectAppComponentState,
  (state: AppComponentState) => state.messageDetails
);
