import { inject, Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserManagementService } from '../services/user-management-service/user-management.service';
import { AdiUserDetailsService } from '../../../shared/services/adi-user-details/adi-user-details.service';
import * as UserManagementActions from './user-management.actions';
import { select, Store } from '@ngrx/store';
import {
  selectActiveTab,
  selectAssociatedFacilities,
  selectUserDetailsData,
  selectUserManagementPermissionObj,
} from './user-management.selectors';
import { constructEntitlementPayload } from './utils/user-management.effect.util';
import { AlertMessageService } from '../../../shared/services/alert-message-service/alert-message.service';
import * as SharedAction from '../../../shared/state/shared-action';

@Injectable()
export class UserManagementEffects {
  store = inject(Store);
  actions$ = inject(Actions);
  userService = inject(UserManagementService);
  adiUserService = inject(AdiUserDetailsService);
  alertService = inject(AlertMessageService);

  // Get Users Data
  loadUsersData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.getUsersData),
      switchMap((action) =>
        this.userService
          .getUserDetails(
            action.order,
            action.pageSize,
            action.page,
            action.selectedAccountIds,
            action?.label,
            action?.searchText
          )
          .pipe(
            map((usersData) =>
              UserManagementActions.getUsersDataSuccess({ usersData })
            ),
            catchError(() => [UserManagementActions.getUsersDataFailure()])
          )
      )
    )
  );

  // Get User Details Data
  loadUserDetailsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.getUserDetailsData),
      switchMap((action: { params: any; practiceOrgId: string | undefined }) =>
        this.userService
          .getUserDetailByParams(action.params, action.practiceOrgId)
          .pipe(
            // Use mergeMap to dispatch multiple actions based on conditions
            mergeMap((userDetailsData) => {
              const successAction =
                UserManagementActions.getUserDetailsDataSuccess({
                  userDetailsData,
                });

              // This action is to construct permission object once we receive the uer details response
              const permissionAction =
                UserManagementActions.getUserManagementPermissionObj({
                  activeTab:
                    userDetailsData?.associatedAccounts &&
                    userDetailsData?.associatedAccounts.length === 1
                      ? userDetailsData?.associatedAccounts[0]?.type
                      : 'ASC',
                });

              // If clinic type found, then call associated facilties API
              if (
                userDetailsData?.associatedAccounts?.find(
                  (data) => data?.type?.toLowerCase() === 'clinic'
                )?.practiceOrg
              ) {
                const additionalAction =
                  UserManagementActions.getAssociatedFacilities({
                    practiceOrgId:
                      userDetailsData?.associatedAccounts?.find(
                        (data) => data?.type?.toLowerCase() === 'clinic'
                      )?.practiceOrg ?? '',
                    calledAfterUserDetailsAPI: true,
                    userDetailsData: userDetailsData,
                  });

                return [additionalAction];
              }

              // If not clinic type, dont call the associated facilities API
              return [successAction, permissionAction];
            }),
            catchError(() => [
              UserManagementActions.getUserDetailsDataFailure(),
            ])
          )
      )
    )
  );

  // Get Associated Facilities ( if facility type is clinic )
  loadAssociatedFacilitiesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.getAssociatedFacilities),
      switchMap(
        (action: {
          practiceOrgId: string;
          calledAfterUserDetailsAPI?: boolean;
          userDetailsData?: any;
        }) =>
          this.userService.getAssociatedFacilities(action.practiceOrgId).pipe(
            mergeMap((associatedFacilities) => {
              const successAction =
                UserManagementActions.getAssociatedFacilitiesSuccess({
                  associatedFacilities,
                });

              // This action is to construct permission object once we receive the uer details response
              const permissionAction =
                UserManagementActions.getUserManagementPermissionObj({
                  activeTab:
                    action?.userDetailsData?.associatedAccounts &&
                    action?.userDetailsData?.associatedAccounts.length === 1
                      ? action?.userDetailsData?.associatedAccounts[0]?.type
                      : 'ASC',
                  associatedFacilities: associatedFacilities,
                });

              if (action?.calledAfterUserDetailsAPI) {
                const additionalAction =
                  UserManagementActions.getUserDetailsDataSuccess({
                    userDetailsData: action?.userDetailsData,
                  });

                return [successAction, additionalAction, permissionAction];
              }

              return [successAction, permissionAction];
            }),
            catchError(() => [
              UserManagementActions.getAssociatedFacilitiesFailure(),
            ])
          )
      )
    )
  );

  // Save Permissions
  savePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserManagementActions.savePermissions),
      withLatestFrom(
        this.store.pipe(select(selectUserDetailsData)),
        this.store.pipe(select(selectAssociatedFacilities)),
        this.store.pipe(select(selectUserManagementPermissionObj)),
        this.store.pipe(select(selectActiveTab))
      ),
      switchMap(
        ([
          action,
          userDetailsData,
          associatedFacilities,
          permissionJson,
          activeTab,
        ]) => {
          // Extract logic to find associatedAccount once
          const associatedAccount = userDetailsData.associatedAccounts?.find(
            (account: any) =>
              account?.type?.toLowerCase() === activeTab?.toLowerCase()
          );

          const payload = constructEntitlementPayload(
            permissionJson,
            userDetailsData,
            associatedFacilities,
            activeTab
          );

          if (!associatedAccount || !payload) {
            // Handle error or return early if no account is found
            return [UserManagementActions.savePermissionsFailure()];
          }

          // Construct the entitlement payload and call the service
          return this.adiUserService
            .updateEntitlement(
              action.emailId,
              associatedAccount.sapId,
              associatedAccount.practiceOrg,
              payload
            )
            .pipe(
              map((savePermissionData) =>
                UserManagementActions.savePermissionsSuccess({
                  savePermissionData,
                })
              ),
              catchError(() => [UserManagementActions.savePermissionsFailure()])
            );
        }
      )
    )
  );

  // Toast message
  successMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserManagementActions.savePermissionsSuccess),
        tap((value) => {
          switch (value?.type) {
            case UserManagementActions.savePermissionsSuccess?.type: {
              this.store.dispatch(
                SharedAction.showToastMessage({
                  toastMessage: 'Changes saved successfully.',
                  toastType: 'success',
                })
              );
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );

  errorMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserManagementActions.savePermissionsFailure),
        tap((value) => {
          switch (value?.type) {
            case UserManagementActions.savePermissionsFailure?.type: {
              this.store.dispatch(
                SharedAction.showToastMessage({
                  toastMessage: 'Please try again. An error occured.',
                  toastType: 'error',
                })
              );
              break;
            }

            default:
              break;
          }
        })
      ),
    { dispatch: false }
  );
}
