<div class="mainWrapper">
  @if (showHeader) {
    <adi-header-lib
      #headerComponent
      (loader)="showHideLoader($event)"
      (updateNotificationsList)="updateNotificationsList()"
      [host]="host"
      [source]="source"
    ></adi-header-lib>
    <adi-connect-facility [host]="host" [source]="source">
    </adi-connect-facility>
    @if (showAccountSelector) {
      <div class="account-selector">
        <alcon-account-selector-lib
          (loader)="showHideLoader($event)"
          [host]="host"
          [source]="source"
        >
        </alcon-account-selector-lib>
      </div>
    }
  }

  @if (this.showAlert()) {
    <div class="alert-container">
      <adi-alert
        [alertType]="alertMessage().type"
        [message]="alertMessage().value"
        [customClass]="alertCustomClass()"
        (closeAlert)="closeAlert()"
      ></adi-alert>
    </div>
  }

  <!-- Adding ngrx toast message which will eventually replace the alert service -->
  @if (showAlert$ | async) {
    <div class="alert-container">
      <adi-alert
        [alertType]="(toastMessageDetails$ | async)?.toastType"
        [message]="(toastMessageDetails$ | async)?.toastMessage"
        [customClass]="(toastMessageDetails$ | async)?.customClass"
        (closeAlert)="closeAlert()"
      ></adi-alert>
    </div>
  }

  <router-outlet />

  @if (showFooter && !showGuestFooter) {
    <adi-footer-lib [host]="host" [source]="source"></adi-footer-lib>
  }
  @if (showGuestFooter) {
    <adi-footer></adi-footer>
  }
  @if (loader) {
    <adi-loader> </adi-loader>
  }
</div>
