import * as newPermissions from '../../../../shared/json/adi-new-permissions.json';

export function modifyNewPermissionList(
  res: any,
  activeTabSelected: string,
  associatedFacilities?: any
) {
  let newPermissionList = JSON.parse(
    JSON.stringify(newPermissions.permissions)
  );

  if (res && activeTabSelected) {
    let permissionList = newPermissions;
    const activeTab = activeTabSelected === 'ASC' ? 'ASC' : 'CLINIC';
    const activeTabIndexInAPI =
      res?.associatedAccounts?.findIndex(
        (val: any) => val?.type === activeTabSelected
      ) ?? 0;

    // Creating deep copies
    let activeTabPermissionsInJson = null;
    if (permissionList.permissions[activeTab]) {
      activeTabPermissionsInJson = JSON.parse(
        JSON.stringify(permissionList.permissions[activeTab])
      );
    }

    // Creating deep copies
    let activeTabPermissionsInAPI = null;
    if (res?.associatedAccounts[activeTabIndexInAPI]?.permissions) {
      activeTabPermissionsInAPI = JSON.parse(
        JSON.stringify(
          res?.associatedAccounts[activeTabIndexInAPI]?.permissions
        )
      );
    }

    // First set the toggle values
    for (let key in activeTabPermissionsInJson) {
      if (activeTabPermissionsInAPI[key]) {
        activeTabPermissionsInJson[
          key as keyof typeof activeTabPermissionsInJson
        ].value = activeTabPermissionsInAPI[key]?.active;

        // Second change the permission set (special case for CC)
        switch (key?.toLowerCase()) {
          case 'cc':
            if (
              activeTabPermissionsInAPI[key]?.permissionSet['surgeriesDash']
            ) {
              for (let permissionKey in activeTabPermissionsInAPI[key]
                ?.permissionSet['surgeriesDash']) {
                if (
                  activeTabPermissionsInJson[key]?.permissionSet[permissionKey]
                    ?.options
                ) {
                  activeTabPermissionsInJson[key]?.permissionSet[
                    permissionKey
                  ]?.options?.every((val: any) => {
                    if (
                      val?.code ===
                      activeTabPermissionsInAPI[key]?.permissionSet[
                        'surgeriesDash'
                      ][permissionKey]
                    ) {
                      val.value = true;
                    } else {
                      val.value = false;
                    }

                    return true;
                  });
                }
              }
            }
            break;

          default:
            for (let permissionKey in activeTabPermissionsInAPI[key]
              ?.permissionSet) {
              // Case - Both checkbox and radio buttons present

              // Set checkbox

              // Set Radio Buttons
              if (
                activeTabPermissionsInJson[key]?.permissionSet[permissionKey]
                  ?.options
              ) {
                activeTabPermissionsInJson[key]?.permissionSet[
                  permissionKey
                ]?.options?.every((val: any) => {
                  if (
                    val?.code ===
                    activeTabPermissionsInAPI[key]?.permissionSet[permissionKey]
                  ) {
                    val.value = true;
                  } else {
                    val.value = false;
                  }

                  return true;
                });
              }
              // Case - Only checkboz present
              else if (
                activeTabPermissionsInJson[key]?.permissionSet[
                  permissionKey
                ]?.hasOwnProperty('value')
              ) {
                activeTabPermissionsInJson[key].permissionSet[
                  permissionKey
                ].value =
                  activeTabPermissionsInAPI[key]?.permissionSet[permissionKey];
              }
            }

            break;
        }
      }
    }

    // Constructing Associated Facilities Object
    // Case 1 - Associated Facilities exist
    if (
      activeTabSelected?.toLowerCase() === 'clinic' &&
      associatedFacilities?.associatedFacilities?.length > 0
    ) {
      let initialPermissionObject: any = [];

      associatedFacilities?.associatedFacilities?.forEach((val: any) => {
        let scheduleMgmtPermission = activeTabPermissionsInAPI[
          'SCHED'
        ]?.permissionSet?.associatedFacilitiesPermission?.reduce(
          (acc: any, perm: any) => {
            if (perm?.ascId === val?.practiceOrg) {
              acc['scheduleMgmt'] = perm?.scheduleMgmt;
              acc['ascId'] = perm?.ascId;
            }
            return acc;
          },
          {}
        );

        // If its empty object, it means all facilities have no access
        if (scheduleMgmtPermission) {
          if (!(Object.keys(scheduleMgmtPermission)?.length > 0)) {
            scheduleMgmtPermission = null;
          }
        }

        if (
          val?.permissions['SCHED']?.permissionSet &&
          val?.permissions['SCHED']?.permissionSet['scheduleMgmt']
        ) {
          initialPermissionObject.push({
            name: val?.name,
            maxPermissionLevel:
              val?.permissions['SCHED']?.permissionSet['scheduleMgmt'],
            scheduleMgmt: scheduleMgmtPermission
              ? scheduleMgmtPermission?.scheduleMgmt
              : 'noAccess',
            ascId: scheduleMgmtPermission
              ? scheduleMgmtPermission?.ascId
              : val?.practiceOrg,
          });
        }
      });

      // Constructing the final Json Table Body
      const newTableBody = constructTableBody(
        activeTabPermissionsInJson,
        initialPermissionObject
      );

      activeTabPermissionsInJson[
        'SCHED'
      ].permissionSet.associatedFacilities.tableBody = [...newTableBody];
    }
    // Case 2 - Associated Facilities do not exist (delete associated facilities property from json)
    else if (
      activeTabSelected?.toLowerCase() === 'clinic' &&
      !(associatedFacilities?.associatedFacilities?.length > 0)
    ) {
      activeTabPermissionsInJson[
        'SCHED'
      ].permissionSet.associatedFacilities.show = false;
    }

    if (activeTabPermissionsInJson) {
      newPermissionList[activeTab] = activeTabPermissionsInJson;
    }
  }

  return newPermissionList;
}

function constructTableBody(
  activeTabPermissionsInJson: any,
  initialPermissionObject: any
) {
  let tableBody: any = [];
  const initialSubObj = {
    ...activeTabPermissionsInJson['SCHED']?.permissionSet?.associatedFacilities
      ?.tableBody[0],
  };

  initialPermissionObject?.forEach((data: any) => {
    if (data) {
      const editedObj = JSON.parse(JSON.stringify(initialSubObj));

      editedObj.practice.value = data?.name;
      editedObj.practice.ascId = data?.ascId;

      const selectedScheduleMgmtOption = editedObj?.scheduleMgmt?.options?.find(
        (option: any) => option?.key === data?.scheduleMgmt
      );
      if (selectedScheduleMgmtOption) {
        editedObj.scheduleMgmt.value = selectedScheduleMgmtOption;
      }

      const selectedMaxPermissionOption =
        editedObj?.maxPermissionLevel?.options?.find(
          (option: any) => option?.key === data?.maxPermissionLevel
        );
      if (selectedMaxPermissionOption) {
        editedObj.maxPermissionLevel.value = selectedMaxPermissionOption?.label;
      }

      if (data?.maxPermissionLevel === 'view') {
        editedObj.scheduleMgmt.options =
          editedObj?.scheduleMgmt?.options?.filter(
            (opt: any) => opt?.key !== 'manage'
          );
      }

      tableBody?.push(editedObj);
    }
  });

  return tableBody;
}
