import { createReducer, on } from '@ngrx/store';
import * as SharedAction from '../shared/state/shared-action';
import * as AppComponentAction from './app-component.actions';

export interface AppComponentState {
  showToastAlert: boolean;
  messageDetails: {
    toastType: 'success' | 'error';
    toastMessage: string;
    customClass?: string;
  } | null;
}

export const initialState: AppComponentState = {
  showToastAlert: false,
  messageDetails: null,
};

export const appComponentReducer = createReducer(
  initialState,
  on(SharedAction.showToastMessage, (state, action) => ({
    ...state,
    showToastAlert: true,
    messageDetails: action,
  })),
  on(SharedAction.closeToastMessage, (state, action) => ({
    ...state,
    showToastAlert: false,
    messageDetails: null,
  })),

  on(AppComponentAction.closeToastMessage, (state, action) => ({
    ...state,
    showToastAlert: false,
    messageDetails: null,
  }))
);
