import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountSelectorService } from '../../../features/account-selector/services/account-selector.service';
import { LocalStorageKey } from '../../constants/local-storage-key.const';
import { AdiUserDetailsService } from '../../services/adi-user-details/adi-user-details.service';
import { UserDetails } from '../../interfaces/userDetails.interface';

export const AccountSelectorGuard: CanActivateFn = (route, state) => {
  const userDetailsService = inject(AdiUserDetailsService);
  const accountSelectorService = inject(AccountSelectorService);
  const router = inject(Router);
  const currentPath = state.url;
  const lastVisitedPath = localStorage.getItem(
    LocalStorageKey.LAST_VISITED_PATH
  );

  if (!currentPath.includes('account-selector')) {
    localStorage.setItem(LocalStorageKey.LAST_VISITED_PATH, currentPath);
  }

  userDetailsService
    .getCurrentUserDetails()
    .subscribe((res: UserDetails | undefined) => {
      if (res?.associatedAccounts) {
        accountSelectorService.setAccounts(res?.associatedAccounts!);
        if (localStorage.getItem(LocalStorageKey.SELECTED_ACCOUNT_ID)) {
          if (
            lastVisitedPath &&
            !lastVisitedPath.includes('account-selector')
          ) {
            router.navigate([lastVisitedPath]);
            return false;
          } else {
            return true;
          }
        } else {
          if (res?.associatedAccounts.length > 1) {
            return true;
          } else {
            localStorage.setItem(
              LocalStorageKey.SELECTED_ACCOUNT_ID,
              res.associatedAccounts[0].sapId
            );
            localStorage.setItem(
              LocalStorageKey.SELECTED_PRACTICE_ORG_ID,
              res.associatedAccounts[0].practiceOrg
            );
            localStorage.setItem(
              LocalStorageKey.SELECTED_SALESORG_ID,
              res.associatedAccounts[0].salesOrg
            );
            localStorage.setItem(
              LocalStorageKey.SELECTED_ACCOUNT_TYPE,
              res.associatedAccounts[0].type
            );

            router.navigate([`${LocalStorageKey.SELECTED_LANGUAGE}/dashboard`]);
            return false;
          }
        }
      }
      return true;
    });
  return true;
};
