import { createReducer, on } from '@ngrx/store';
import * as UsermanagementActions from './user-management.actions';
import { AutoSchedulerPermissions } from '../../../shared/interfaces/userPermissions.interface';
import { modifyNewPermissionList } from './utils/user-management.reducer.util';

// Define initial state
export interface UsermanagementState {
  userDetailsLoading: boolean;
  usersData: any | null;
  userDetailsData: any | null;
  associatedFacilities: any | null;
  activeTab: string;
  userManagementPermissionObj: any | null;
  savePermissionsData: any | null;
  savePermissionLoading: boolean;
}

export const initialState: UsermanagementState = {
  userDetailsLoading: true,
  usersData: null,
  userDetailsData: null,
  associatedFacilities: null,
  activeTab: '',
  userManagementPermissionObj: null,
  savePermissionsData: null,
  savePermissionLoading: false,
};

// Reducer to handle actions
export const usermanagementReducer = createReducer(
  initialState,

  // Get Users Data
  on(UsermanagementActions.getUsersData, (state) => ({
    ...state,
    usersData: null,
  })),
  on(UsermanagementActions.getUsersDataSuccess, (state, { usersData }) => ({
    ...state,
    usersData: usersData,
  })),
  on(UsermanagementActions.getUsersDataFailure, (state) => ({
    ...state,
    usersData: null,
  })),

  // Get User Details Data
  on(UsermanagementActions.getUserDetailsData, (state) => ({
    ...state,
    userDetailsData: null,
    userManagementPermissionObj: null,
    userDetailsLoading: true,
    activeTab: '',
  })),
  on(
    UsermanagementActions.getUserDetailsDataSuccess,
    (state, { userDetailsData }) => ({
      ...state,
      activeTab:
        userDetailsData?.associatedAccounts?.length === 1
          ? userDetailsData?.associatedAccounts[0]?.type
          : 'ASC',
      userDetailsData: { ...userDetailsData },
      userDetailsLoading: false,
    })
  ),
  on(UsermanagementActions.getUserDetailsDataFailure, (state) => ({
    ...state,
    userDetailsData: null,
    userDetailsLoading: false,
  })),

  // Get Associated Facilities
  on(UsermanagementActions.getAssociatedFacilities, (state) => ({
    ...state,
    associatedFacilities: null,
    userDetailsLoading: true,
  })),
  on(
    UsermanagementActions.getAssociatedFacilitiesSuccess,
    (state, { associatedFacilities }) => ({
      ...state,
      associatedFacilities: associatedFacilities,
      userDetailsLoading: false,
    })
  ),
  on(UsermanagementActions.getAssociatedFacilitiesFailure, (state) => ({
    ...state,
    associatedFacilities: null,
    userDetailsLoading: false,
  })),

  // Get User Management Permission Object
  on(UsermanagementActions.getUserManagementPermissionObj, (state, action) => ({
    ...state,
    userManagementPermissionObj: action?.permissionObject
      ? action?.permissionObject
      : modifyNewPermissionList(
          state.userDetailsData,
          action?.activeTab ? action.activeTab : state.activeTab,
          action?.associatedFacilities
            ? action?.associatedFacilities
            : state?.associatedFacilities
        ),
  })),

  // Toggle Active Tab
  on(UsermanagementActions.toggleActiveTab, (state, action) => ({
    ...state,
    activeTab: action?.activeTab,
    userManagementPermissionObj: modifyNewPermissionList(
      state.userDetailsData,
      action?.activeTab,
      state?.associatedFacilities
    ),
  })),

  // Save Permissions
  on(UsermanagementActions.savePermissions, (state) => ({
    ...state,
    savePermissionsData: null,
    savePermissionLoading: true,
  })),
  on(UsermanagementActions.savePermissionsSuccess, (state, action) => ({
    ...state,
    savePermissionsData: action.savePermissionData,
    savePermissionLoading: false,
  })),
  on(UsermanagementActions.savePermissionsFailure, (state) => ({
    ...state,
    savePermissionsData: null,
    savePermissionLoading: false,
  })),

  // Empty all data in user details page
  on(UsermanagementActions.emptyUsersAndUserDetailsData, (state) => ({
    ...state,
    usersData: null,
    userDetailsData: null,
    associatedFacilities: null,
    userManagementPermissionObj: null,
    userDetailsLoading: true,
    savePermissionsData: null,
    savePermissionLoading: false,
  }))
);
