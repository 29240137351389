import { createAction, props } from '@ngrx/store';

export const showToastMessage = createAction(
  '[Shared] Show Toast Message',
  props<{
    toastType: 'success' | 'error';
    toastMessage: string;
    customClass?: string;
  }>()
);

export const closeToastMessage = createAction('[Shared] Close Toast Message');
